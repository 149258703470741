<template>
    <div id="ListAsosExame">
        <b-card-group deck>
            <b-card title="" body-class="text-center" header-tag="nav">
                <template #header>
                    <b-nav card-header tabs>
                        <b-nav-item active><b>Pesquisa ASO's </b></b-nav-item>
                    </b-nav>
                </template>
                <b-row>
                    <b-col cols="8">
                        <b-input-group prepend="Empresa">
                            <b-form-input name="filter-input" id="filter-input" v-model="filter"
                                v-on:keyup="searchEmpresa" type="search"
                                placeholder="Pesquis Nome da Empresa"></b-form-input>
                            <b-form-input name="filter-input_cnpj" id="filter-input_cnpj" v-model="filter2"
                                v-on:keyup="searchEmpresaCnpj" type="search"
                                placeholder="Pesquisa pelo Cnpj"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <br>
                <b-row>
                    <b-col cols="8">
                        <b-input-group prepend="Paciente">
                            <b-form-input name="filter-input_paciente" id="input_paciente-input" v-model="filter3"
                                v-on:keyup="searchPaciente" type="search"
                                placeholder="Pesquisa Nome Paciente"></b-form-input>
                            <b-form-input name="filter-input_cpf" id="filter-input_cpf" v-model="filter4"
                                v-on:keyup="searchPacienteCpf" type="search"
                                placeholder="Pesquisa pelo Cpf Paciente"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
                <br>
                <br>
                <b-table striped hover responsive id="my-table" :items="listAsos" :fields="visibleFields"
                    :per-page="perPage" :current-page="currentPage" :filter="filter" aria-controls="my-table" small>
                    <template #cell(opcao)="data">

                        <router-link class="home-link"
                            :to="{ name: 'uploadexames', query: { paciente_id: data.item.paciente_id, aso_id: data.item.id, convenio_empresa: data.item.empresa.convenio } }">
                            <b-icon icon="cloud-upload" cursor="pointer" title="Cadastrar Arquivo Exame" pill
                                variant="success" size="sm" font-scale="1.3"></b-icon>
                        </router-link>

                    </template>
                </b-table>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table"></b-pagination>
                <!-- Info modal ADICIONAR EXAMES COMPLEMENTARES -->
                <b-modal size="xl" v-model="modalShow" hide-footer>
                    <exames-ocupacionais :dados="dadosAso"> </exames-ocupacionais>
                </b-modal>
            </b-card>
        </b-card-group>
    </div>

</template>

<script>

const moment = require("moment");
import ExamesOcupacionais from '@/components/imprimir/ExamesOcupacionais'
import { http } from '@/plugins/axios'
import maskMixins from '@/helpers/mixins/mask'  
import { mapState  } from 'vuex'

export default {
    components: {ExamesOcupacionais},
    mixins: [maskMixins],
    data(){
        return {
            isBusy: false,
            perPage: 20,
            currentPage: 1,
              filter: '',
              filter2: '',
              filter3: '',
              filter4: '',
            fields: [
                {
                    key: 'id',
                    label: 'id',
                    visible: false,
                    sortable: true,
                    thStyle: { width: "10%" },
                    tdClass: 'fonteLinhasLeft'
                },
                {
                    key: 'codigoaso',
                    label: 'ASO',
                    visible: true,
                    sortable: true,
                    thStyle: { width: "3%" }
                   
                },
                {
                    key: 'paciente.nome',
                    label: 'Paciente',
                    sortable: true,
                    visible: true,
                    thStyle: { width: "25%" },
                    tdClass: 'fonteLinhasLeft'
                },
                {
                    key: 'paciente.cpf',
                    label: 'Cpf',
                    sortable: true,
                    visible: true,
                    thStyle: { width: "8%" },
                    tdClass: 'fonteLinhasLeft'
                }, 
                {
                    key: 'tipoaso.descricao',
                    label: 'TIPO ASO',
                    visible: true,
                    sortable: true,
                    thStyle: { width: "7%" },
                    tdClass: 'fonteLinhasLeft'
                },
                {
                    key: 'empresa.nome',
                    label: 'Empresa',
                    sortable: true,
                    visible: true,
                    thStyle: { width: "35%" },
                    tdClass: 'fonteLinhasLeft'
                }, 
                {
                    key: 'created_at',
                    label: 'Data',
                //    formatter: 'formatarDataEntradaParaGrid',
                    sortable: true,
                    visible: true,
                    thStyle: { width: "8%" },
                    tdClass: 'fonteLinhasLeft'
                }, 

                {
                    key: 'opcao',
                    visible: true,
                    label: 'Opções',
                    thStyle: { width: "20%" },
                    sortable: true
                }
            ], 
            user_id:'',
            examesAso:[],
            xml:'',
            dadosAso:[],
            listAsos: [],
            modalShow: false,
            editedIndex: -1
        }
    },
    mounted(){
      //  this.ActionFindAsos(),
            setTimeout(() => {// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                this.isBusy = false
            }, 2.0 * 2000)
    },
 
    computed: {
        
        ...mapState('auth', ['user']),

        visibleFields() {
            return this.fields.filter(field => field.visible)
        },

        rows() {
            return this.listAsos.length
               },
    },
    methods: {

      async searchEmpresa(e) {

         if(e.target.value.length > 3){

                    await http.get('/aso/search/asonomeempresa/'+e.target.value).then(resp=>{
                         
                        (resp.data).forEach(function (item){//tratando as datas 
                                        item.created_at = moment(item.created_at).format("DD/MM/yyyy")
                                        item.empresa.nome = item.empresa.nome+' -'+item.empresa.cnpj
                                    })
                              this.listAsos=resp.data
                         })  
                    }

          },


          async searchEmpresaCnpj(e) {

               if(e.target.value.length > 3){
                  await http.get('/aso/search/asocnpjempresa/'+e.target.value).then(resp=>{
                         
                        (resp.data).forEach(function (item){//tratando as datas 
                                        item.created_at = moment(item.created_at).format("DD/MM/yyyy")
                                        item.empresa.nome = item.empresa.nome+' -'+item.empresa.cnpj
                                    })
                              this.listAsos=resp.data
                         })  
                    }
        },

        async searchPaciente(e) {

               if(e.target.value.length > 3){

                    await http.get('/aso/search/asonomepaciente/'+e.target.value).then(resp=>{
                         
                        (resp.data).forEach(function (item){//tratando as datas 
                                        item.created_at = moment(item.created_at).format("DD/MM/yyyy")
                                        item.empresa.nome = item.empresa.nome+' -'+item.empresa.cnpj
                                    })
                              this.listAsos=resp.data
                         })  

                      }
          },



         async searchPacienteCpf(e) {

                if(e.target.value.length > 3){

                    await http.get('/aso/search/asocpfpaciente/'+e.target.value).then(resp=>{
                         
                        (resp.data).forEach(function (item){//tratando as datas 
                                        item.created_at = moment(item.created_at).format("DD/MM/yyyy")
                                        item.empresa.nome = item.empresa.nome+' -'+item.empresa.cnpj
                                    })
                              this.listAsos=resp.data
                         })  

                      }
        },


        habilitaEsocial(item) {
            return item ? false : true
        },

        iconVisible(master) {

            if (master === true) {
                return false
            }
            else {
                return true
            }
        },

        
        
     /*   async ActionFindAsos(){
              await http.get('/aso').then(resp=>{
                  (resp.data).forEach(function (item){//tratando as datas 
                      item.created_at = moment(item.created_at).format("DD/MM/yyyy")
                  })
                  this.listAsos=resp.data
              })  
        },
    */

        
        async ActionFindAsoId(){},

        enviarAso(items) { //versao esocial  2.4.02
           
            http.get('/aso/txt2/' + items.id).then(response => {
                 this.xml = response.data;
                console.log('item--->' + this.xml)
            })

        },
        
        imprimirAso(item){

            //pesquisa os exames
            http.get('/examesaso/' + item.id).then(response => {
                this.examesAso = response.data
              //  this.modalShow = true;
                item['procedimentos'] = this.examesAso
                this.dadosAso = item
            })
        },

        editItem(item){
           // console.log(item)
              http.get('/aso/xml/'+item.id).then(resp=>{
                console.log(resp.data)
              })  
        },


        mascaraCnpj(value) {
            var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
            return mascara;
        },
        formatarData: function (value) {
            if (value === null) { return null }
            return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        }
    }
}
</script>
<style >
.fonteLinhas {
    font-size: 14px;
    text-align: center
}

.fonteLinhasLeft {
    font-size: 14px;
    text-align: left
}

.select-selected {
    border-color: black;
    border: 6px solid;
}

.select-selected.select-arrow-active:after {
    border-color: black;
    top: 7px;
}

.select-items div,
.select-selected {
    color: black;
    padding: 8px 16px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.select-items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

.select-hide {
    display: none;
}

.pesquisa_select {

    position: relative;
    margin-top: 20px;

}


.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

@mixin flex-center($columns: false) {
    display: flex;
    align-items: center;
    justify-content: center;

    @if $columns {
        flex-direction: column;
    }
}
</style>