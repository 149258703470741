<template>
<div id="AsoExame" >
  <b-card-group deck>
  <b-card title="" body-class="text-center" header-tag="nav" style="max-width: 80rem;">
        <template #header>
          <b-nav card-header tabs>
            <b-nav-item active><b>Atestado de Saúde Ocupacional - ASO </b></b-nav-item>
          </b-nav>
        </template>
 <b-container style="margin-top: 20px; margin-left: 10px">
 <b-form @submit.prevent="save">
      <b-row>
         <b-col>
          <b-form-group class="fonteItem"   label="Nome:" label-for="nested-country" label-cols-sm="3" label-align-sm="left">
            <label class="fonteCampo"> &nbsp;{{ dadosPaciente.nome }} </label>
            <b-form-input hidden="true" id="pacienteid" name="pacienteid" v-model="formdata.paciente_id"></b-form-input>
            <b-form-input hidden="true" id="empresaid" name="empresaid" v-model="formdata.empresa_id"></b-form-input>
            <b-form-input hidden="true" id="funcaoid" name="funcaoid" v-model="formdata.funcao_id"></b-form-input>
            <b-form-input  hidden="true" id="telefone_empresa" name="telefone_empresa"  v-model="formdata.telefone_empresa"></b-form-input>
          </b-form-group>
         </b-col>

        <b-col>
          <b-form-group class="fonteItem" label="Cpf:"
        label-for="nested-country"
        label-cols-sm="3"
        label-align-sm="left"><label class="fonteCampo">&nbsp;{{ dadosPaciente.cpf}}</label>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group class="fonteItem"   label="Função:"
        label-for="nested-country"
        label-cols-sm="3"
        label-align-sm="left"><label class="fonteCampo">&nbsp;{{ dadosPaciente.funcao.name}}</label>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>
        <b-col>
          <b-form-group class="fonteItem"   label="Empresa:"
        label-for="nested-country"
        label-cols-sm="3"
        label-align-sm="left"><label class="fonteCampo">&nbsp;{{ dadosPaciente.empresa.nome}}  </label>
        </b-form-group>
        </b-col>
        
        <b-col>
          <b-form-group class="fonteItem" label="Telefone:"
        label-for="nested-country"
        label-cols-sm="3"
        label-align-sm="left"><label class="fonteCampo">&nbsp;{{ dadosPaciente.empresa.telefone}}  </label>
          </b-form-group>
        </b-col>
      
      </b-row>

       <b-row>  
            <b-col>
              <b-form-group class="fonteItem"   label="Cnpj/Cpf:" label-for="nested-country"  label-cols-sm="3" label-align-sm="left">
                <label class="fonteCampo">&nbsp;{{ dadosPaciente.empresa.cnpj}} / {{ dadosPaciente.empresa.cpf}}</label>
              </b-form-group>
            </b-col>
            <b-col >
                <b-form-group class="fonteItem"   label="Convênio:" label-for="nested-country" label-cols-sm="3" label-align-sm="left">
                  <label class="fonteCampo">&nbsp;{{ dadosPaciente.empresa.convenio ? 'ATIVO' : 'DESATIVADO' }} </label>
                </b-form-group>
            </b-col>
       </b-row>

      <b-row>
        <b-col>
          <b-form-group   class="fonteItem"   label="Obervação:" label-for="nested-country" label-cols-sm="3" label-align-sm="left"><label class="fonteTituloFormRed">{{dadosPaciente.empresa.observacao}}</label> 
          </b-form-group>
        </b-col>
      </b-row>

<!-- FIM DADOS DO PACIENTE E DA EMPRESA  -->
<b-card  header="Ficha de Exame" header-class="fonteTituloHeader"  header-tag="header" footer="" footer-tag="footer"  >
          <b-row>
            <b-col md="2"  class="alinhaFormAso">
              <b-form-group class="mb-3" label="">
                <b-card-text><label class="fonteTituloForm">Tipo Aso:&nbsp;</label>
                <b-form-select id="tipoaso_id" name="tipoaso_id" v-model="formdata.tipoaso_id">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Selecione o Tipo Aso</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="(item,index) in tableTipoAso" v-bind:key="index.id" v-bind:value="item.id">
                    {{ item.descricao }}
                  </b-form-select-option>
                </b-form-select>
                </b-card-text>
              </b-form-group>
            </b-col>
            &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
            <b-col md="2" class="alinhaFormAso">
              <b-form-group class="mb-3" >
                <b-card-text><label class="fonteTituloForm">Tipo Pagamento:&nbsp;</label>
                <b-form-select id="tipopagamento" name="tipopagamento" v-model="formdata.tipopagamento_id">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Selecione o Tipo Pagamento</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="(item,index) in listTipoPagamento" v-bind:key="index.id"
                    v-bind:value="item.id">
                    {{ item.descricao }}
                  </b-form-select-option>
                </b-form-select>
                  </b-card-text>
              </b-form-group>
            </b-col>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
            <b-col md="2"   class="alinhaFormAso">
              {{ medico }}
              <b-form-group class="mb-3"  >
                <b-card-text><label class="fonteTituloForm">Médico: &nbsp;</label>
                <b-form-select id="medico_id" name="medico_id" v-model="formdata.medico_id">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Selecione o Medico</b-form-select-option>
                  </template>
                  <b-form-select-option v-for="(item,index) in medicosAtivos" v-bind:key="index.id" v-bind:value="item.id">
                    {{ item.nome }}
                  </b-form-select-option>
                </b-form-select>
              </b-card-text>
              </b-form-group>
            </b-col>
   &nbsp;
            <b-col md="2"  class="alinhaFormAso2">
                <b-form-group class="mb-3"  >
                  <b-card-text><label class="fonteTituloForm">Médico Examinador: &nbsp;</label>
                  <b-form-select id="medicoexaminador_id" name="medicoexaminador_id" v-model="formdata.medicoexaminador_id">
                  <template #first>
                      <b-form-select-option :value="null" disabled>Selecione o Medico</b-form-select-option>
                  </template>
                         <b-form-select-option v-for="(item, index) in medicoExaminador" v-bind:key="index.id" v-bind:value="item.id" >
                      {{ item.nome }}
                    </b-form-select-option>
                  </b-form-select>
                    </b-card-text>
                </b-form-group>
              </b-col>
            &nbsp;
              <b-col  class="alinhaFormAso">
                <b-form-group hidden="true" class="mb-3"><b-card-text><label class="fonteTituloForm">Data Aso:&nbsp;</label>
                    <b-form-input id="input-2" name="dataemissaoaso" disabled class="input" v-model="formdata.datahoje" required>
                    </b-form-input>
                  </b-card-text>
                </b-form-group>
              </b-col>
          </b-row>

          <br />
            <b-row>
               &nbsp;
                <b-col  >
                  <b-button type="submit" pill size="sm" variant="success" :class="visibleBtAso">
                    <span class="fonteCampoBotaoASO"> Gerar ASO </span>
                  </b-button>
                  &nbsp;
                <b-button variant="success" :class="visibleBtExames" @click="createItem" class="mb-2">
                      Adicionar Exames <b-icon cursor="pointer" pill icon="credit-card" aria-hidden="true"></b-icon>
                </b-button>
                </b-col>
               
            </b-row>
          <br/>
                  <!-- PAINEL DE IMPRESSAO DA ASO, FICHA CLINICA E ADD EXAMES COMPLEMENTARES -->
                   <b-row >
                            
                             <b-col :class="visiblePrint" cols="3">
                                <template>
                                  <div >
                                    <aso-pdf :dados="dadosAso"></aso-pdf>
                                  </div>
                                </template>
                             </b-col>

                             <b-col cols="3">
                                  <template>
                                    <div :class="visiblePrint">
                                   <recibo-aso-pdf :dados="dadosAso"></recibo-aso-pdf>
                                    </div>
                                  </template>
                               </b-col>

                             <b-col cols="3">
                                  <template>
                                    <div :class="visiblePrint">
                                      <ficha-clinica-aso-pdf :dados="dadosAso"></ficha-clinica-aso-pdf>
                                    </div>
                                  </template>
                             </b-col>
                              <b-col cols="3">
                                    <template>
                                      <div :class="visiblePrint">
                                        <encaminhamento-pdf :dados="dadosAso"></encaminhamento-pdf>
                                      </div>
                                    </template>
                               </b-col>
                         </b-row>

                    </b-card>

      </b-form>
    </b-container>

    <!-- Info modal ADICIONAR EXAMES COMPLEMENTARES -->
    <b-modal size="xl" v-model="modalShow" title="Adicionar Exames Complementares" hide-footer>
    <b-form-group label-for="aso_id"  label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
      <b-input-group size="sm">
        <b-form-input id="aso_id" hidden="true"  v-model="formdataExames.aso_id" type="text" ></b-form-input>
            <b-form-input id="tipopagamento" hidden="true" v-model="formdataExames.tipopagamento" type="text" > </b-form-input>
      </b-input-group>
    </b-form-group>
           
            <b-row>
              <b-col>
                <p align="left">
                  <b-form-group label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Pesquise exames aqui...">
                      </b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </p>
              </b-col>
            </b-row>

      <b-table striped hover responsive id="my-table" :items="dadosExames" :filter="filter" :fields="visibleFields" :per-page="perPage" :current-page="currentPage" aria-controls="my-table" small>
        <template #cell(opcao)="data">
          <b-icon icon="arrow-down-square" cursor="pointer" @click="saveExames(data.item)" pill variant="success" size="sm"></b-icon>&nbsp;
        </template>
      </b-table>

</b-modal>

    <!--  EXAMES COMPLEMENTARES Ja ADICIONADOS A ASO -->
      <template hidden="true">
        <div>
          <b-table striped hover responsive id="my-table2" :items="dadosExamesAso" :fields="visibleFields2" >
            <template #cell(opcao)="data">
              <b-icon icon="trash" title="Excluir Exame" cursor="pointer" @click="deleteItem(data.item)" pill v-b-modal="'edit-modal'" variant="primary" size="sm"></b-icon>
              &nbsp;
             <audiometria-pdf :hidden="iconVisibleAudiometria(data.item.exame.id)"  :dados="data.item.aso_id"></audiometria-pdf>
             <acuidade-pdf :hidden="iconVisibleAcuidade(data.item.exame.id)"  :dados="data.item.aso_id"></acuidade-pdf>
             <laudo-pcd-pfd :hidden="iconVisibleLaudoPcd(data.item.exame.id)"  :dados="data.item.aso_id"></laudo-pcd-pfd>        
             <romberg-pdf :hidden="iconVisibleRomberg(data.item.exame.id)" :dados="data.item.aso_id"></romberg-pdf>
             <psicosocial-pdf :hidden="iconVisiblePsicoSocial(data.item.exame.id)" :dados="data.item.aso_id"></psicosocial-pdf>
             <teste-cores-pdf :hidden="iconVisibleTesteCores(data.item.exame.id)" :dados="data.item.aso_id" ></teste-cores-pdf>
            </template>
          </b-table>
        </div>
      </template>

  </b-card>
  </b-card-group>

  </div>
</template>
<script>

//imports

//import jsPDF from 'jspdf' 
import { http } from '@/plugins/axios'
import { mapState, mapActions } from 'vuex'
import maskMixins from '@/helpers/mixins/mask'
import AsoPdf from '../../../components/imprimir/AsoPdf.vue'
import ReciboAsoPdf from '../../../components/imprimir/ReciboAsoPdf.vue'
import FichaClinicaAsoPdf from '../../../components/imprimir/FichaClinicaAsoPdf.vue'
import AudiometriaPdf from '../../../components/imprimir/AudiometriaPdf.vue'
import AcuidadePdf from '../../../components/imprimir/AcuidadePdf.vue'
import LaudoPcdPfd from '../../../components/imprimir/LaudoPcdPfd.vue'
import RombergPdf from '../../../components/imprimir/RombergPdf.vue'
import PsicosocialPdf from '../../../components/imprimir/PsicosocialPdf.vue'
import TesteCoresPdf from '../../../components/imprimir/TesteCoresPdf.vue'
import EncaminhamentoPdf from '../../../components/imprimir/EncaminhamentoPdf.vue'


export default {
    components: {AsoPdf, ReciboAsoPdf, FichaClinicaAsoPdf, AudiometriaPdf, AcuidadePdf, LaudoPcdPfd, RombergPdf, PsicosocialPdf, TesteCoresPdf, EncaminhamentoPdf },

  mixins: [maskMixins],
  name: 'AsoPdf2',
  data() {

    return {
      dadosExamesAso: [],
      listTipoPagamento:'',
      itemsExames: [
        {
          key: 'id',
          label: 'id',
          visible: false,
          thStyle: { width: "10%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'exame.name',
          label: 'Exame',
          visible: true,
          sortable: true,
          thStyle: { width: "10%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'exame.valoravista',
          label: 'Valor',
          visible: true,
          sortable: true,
          
          thStyle: { width: "10%" },
          tdClass: 'fonteLinhasLeft',
          formatter: 'convertNumberToReal'
        },
        {
          key: 'opcao',
          visible: true,
          thStyle: { width: "7%" },
          label: 'Opções',
          sortable: true
        }
      ],
      perPage: 10,
      currentPage: 1,
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: 'Adicionar Exames Complementares',
        content: ''
      },
      dadosExames: {},
      fields: [
        {
          key: 'id',
          label: 'id',
          visible: false,
          sortable: true,
          thStyle: { width: "10%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'name',
          label: 'Exame',
          sortable: true,
          visible: true,
          thStyle: { width: "40%" },
          tdClass: 'fonteLinhasLeft'
        },
        {
          key: 'procedimento.codigoesocial',
          label: 'E-Social',
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
          tdClass: 'fonteLinhasLeft'
        }, 
        {
          key: 'valoravista',
          label: 'Valor',
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
          tdClass: 'fonteLinhasLeft',
          formatter: 'convertNumberToReal'
        },
        {
          key: 'opcao',
          visible: true,
          thStyle: { width: "7%" },
          label: 'Opções',
          sortable: true
        }
      ],
      dadosPaciente:{
        id:'',
        nome: '',
        cpf: '',
        funcao:{
          name: ''
        },
        empresa: {
          id:'',
          nome: '',
          cnpj: '',
          cpf: '',
          convenio: ''
        }
      },

      formdataExames:{
        aso_id: '0', 
        tipopagamento:'0'
      },

      formdata: {
        tipoaso_id: '',
        empresa_id: '',
        datahoje:'',
        dataemissaoaso: '',
        tipopagamento_id: '',
        paciente_id: '',
        funcao_id: '',
        medico_id: '',
        medicoexaminador_id:'',
        telefone_empresa:''
      },

      dadosAso: '',
      medicoExaminador: '',
      medico:'',
      medicosAtivos:'',
      modalShow: false,
      editedIndex: -1,
      formdataPesquisa:{
        id: '',
        convenio_empresa: false
      },
      visiblePrint:'invisible',
      visibleBtAso:'visible',
      visibleBtExames:'invisible',
      salvarAso:'sim'
    }
  },
  mounted() {
      
      this.buscaMedicosAtivos(),
      this.buscaMedicoExaminador(),
      this.buscaPaciente(),
      this.buscaExames(),
      this.buscaTipoPagamento(),
      this.ActionTipoAso(),
      this.ActionFindMedico(),
      this.ActionFindByPaciente()
      //this.buscaMedico()
      
      
    
    
  },
  created() {
    this.formdataPesquisa = {
       id: this.$route.query.paciente_id,
       convenio_empresa: this.$route.query.convenio_empresa
    }
  },
  computed: {

    ...mapState('auth', ['user']),
    ...mapState('aso', ['tableTipoAso']),
    ...mapState('medico', ['tableMedicos']),
    ...mapState('paciente', ['tablePacientes']),

    visibleFields(){
      return this.fields.filter(field => field.visible)
    },

    visibleFields2(){
      return this.itemsExames.filter(field => field.visible)
    },

    rows(){
      return this.dadosExames.length
    },

    now(){
      let hoje = new Date()
      let data_formatada = hoje.toLocaleDateString('pt-BR', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      })
      return data_formatada
    }
  },

  methods: {

    ...mapActions('aso', ['ActionTipoAso']),
    ...mapActions('medico', ['ActionFindMedico']),
    ...mapActions('paciente', ['ActionFindByPaciente']),
    ...mapActions('aso', ['ActionSalvar']),
    ...mapActions('exame', ['ActionFindExame']),


    async buscaMedicoExaminador() {

      await http.get('/medicoexaminador').then(resp => {
        resp.data.forEach((item) => {
          if (item.ordem == 1) {
                 this.formdata.medicoexaminador_id = item.id;
          }
        });
          this.medicoExaminador = resp.data
      })
    },


    async buscaMedicosAtivos() {

      await http.get('/medico/medicoativo/list').then(resp => {

        this.medicosAtivos=resp.data
          
      })
    },

/*async buscaMedico() {

      await http.get('/medico').then(resp => {

        console.log(resp)
        //resp.data.forEach((item) => {
          //if (item.ativo == true) {
           //   this.medico = item
         // }
       // });
        this.medico=resp.data
          
      })
    },
*/
    createItem(){
      this.modalShow = true;
    },

    editItem(item) {

      this.modalShow = true;
      this.editedIndex = this.tableExame.indexOf(item);
      this.formdata = Object.assign({}, item);

    },

    iconVisibleTesteCores(idexame) {
      if (idexame === 'a86deaf3-8719-45bc-8afe-48a3892b0c3e') {
        return false
      }
      else {
        return true
      }
    },

    iconVisibleAudiometria(idexame) {
      if (idexame === '8ad2ca34-7f1c-498c-b2ea-f267b01fdd00') {
        return false
      }
      else {
        return true
      }
    },


    iconVisibleAcuidade(idexame) {
      if (idexame === '85e1e173-101e-4b61-9273-84c61e99ea24') {
        return false
      }
      else {
        return true
      }
    },

    iconVisibleLaudoPcd(idexame) {
      if (idexame === 'a9a11aaf-3418-4406-bdb9-a9751d08defb') {
        return false
      }
      else {
        return true
      }

    },

    iconVisibleRomberg(idexame) {
      if (idexame === '12d18c73-85e2-42e4-9a82-cb0501995ff6') {
        return false
      }
      else {
        return true
      }
    },

    iconVisiblePsicoSocial(idexame) {
      if (idexame === 'c9aa9410-5181-400a-b2dd-126f54cdb1c1') {
        return false
      }
      else {
        return true
      }

    },

     close() {
        this.modalShow = false;
         setTimeout(() => {
          this.editedIndex = -1;
           this.buscaExamesAso()
         }, 300);
      },

      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },


    async verificaConvenio(){


        if (this.dadosPaciente.empresa.convenio) {
               
        await http.get('convenioempresa/' + this.dadosPaciente.empresa.id).then(resp => {

              if(resp.data=='') {
                this.salvarAso = 'nao'
                alert('A empresa possui convênio. Porém não existe Exames Cadastrados!')
              }         

          })

        
        }
      
      },

    async buscaExames() {

    //pesquisa se a empresa deste paciente possui convenio para exames
      await http.get('/paciente/' + this.formdataPesquisa.id).then(resp => {
        this.dadosPaciente.empresa.convenio = resp.data.empresa.convenio
        this.dadosPaciente.empresa.id = resp.data.empresa.id
      })

      if(this.dadosPaciente.empresa.convenio){

        //entra para buscar os exames caso tenha convenio
        await http.get('convenioempresa/' + this.dadosPaciente.empresa.id).then(resp => {
          (resp.data).forEach(function (item) {//tratando as datas 
            item.name = item.exame.name
            item.id = item.exame.id
            item.valoravista = item.valorexame
            delete item.empresa_id;
            delete item.user_id;
            delete item.empresa;
            delete item.exame;
            delete item.user;
          })
          this.dadosExames= resp.data
        })
      }

      else{  
        //entra para buscar os exames caso não tenha convenio
        await http.get('exame/').then(resp => {
          this.dadosExames = resp.data
        })
      }
    },

    async buscaTipoPagamento() {

       await http.get('/tipopagamento').then(resp => {
        var list_pagamentos= [];
         if (this.formdataPesquisa.convenio_empresa){
          //exemplo 1
           resp.data.forEach((item)=>{
             if (item.descricao=='convenio'){
               list_pagamentos.push(item);
              }
          });

        }       

         else{
           list_pagamentos = resp.data.filter(item => item.descricao != 'convenio');
         }
         this.listTipoPagamento=list_pagamentos
      })


    },

    async buscaExamesAso() {

        if (this.formdataExames.aso_id==='0'){
                this.formdataExames={
                  aso_id:'0000'
                }
            }

            await http.get('/examesaso/' + this.formdataExames.aso_id ).then(resp => {
                this.dadosExamesAso =  resp.data;
              })

    },

    async buscaPaciente(){

        await http.get('/paciente/' + this.formdataPesquisa.id).then(resp => {
         
          this.dadosPaciente = resp.data
          this.formdata.paciente_id = resp.data.id
          this.formdata.empresa_id = resp.data.empresa.id
          this.formdata.funcao_id = resp.data.funcao.id
          this.formdata.datahoje = this.now
          this.formdata.telefone_empresa = resp.data.empresa.telefone
      })

   },
    

    async save(){ 

      try {
 

       if(this.formdata.telefone_empresa==''){
          alert('Empresa não possui telefone cadastrado')
        }
        else{ 

         //formata a data para enviar o banco
        this.formdata.dataemissaoaso = this.formatarDataParaBanco(this.formdata.datahoje);
        this.formdata.user_id = this.user.id
        this.formdataExames.tipopagamento = this.formdata.tipopagamento_id;
        delete this.formdata.datahoje;
        delete this.formdata.telefone_empresa;

        this.verificaConvenio();

        if(this.salvarAso=='sim'){

            await http.post('/aso/', this.formdata).then(resp => {

                this.formdataExames.aso_id = resp.data.id;
                this.createItem()
                this.buscaExamesAso()
                //formata a data de retorno para o banco
                this.formdata.datahoje = this.formatarDataEntradaParaGrid(resp.data.dataemissaoaso)
            })
      }
    
      this.dadosAso = this.formdataExames.aso_id
        this.visiblePrint = 'visible';
        this.visibleBtAso='invisible';
        this.visibleBtExames = 'visible';
}


      } catch (err) {
        alert(err.data ? err.data.message : 'Não foi possível salvar ASO')
         this.formdata.datahoje = this.now
      }
    }, 


    async saveExames(item) {  

      try {

        //pesquisar depoois os exexams caso a empresa tenha CONVENIO ATIVO
        item.user_id = this.user.id
        item.aso_id = this.formdataExames.aso_id
        item.exame_id = item.id
        item.valorexame = item.valoravista
        item.tipopagamento_id = this.formdataExames.tipopagamento
        delete item.localrealizacaoexame
        delete item.usuarioedicao;
        delete item.usuariocadastro;
        delete item.id;
        delete item.procedimento;
        delete item.name;
        delete item.procRealizado;
        delete item.updated_at;
        delete item.created_at;
        delete item.valoravista;
        delete item.procedimento_id;
       
        await http.post('/examesaso/', item).then(resp => {
           if(resp.data){
            alert('Exame adicionado com sucesso!')
           }
          this.buscaExamesAso()
        })

      } catch (err) {
        alert(err.data ? err.data.message : 'Não foi possível adicionar o exame a ASO')
      }
    }, 

    async deleteItem(data) {

      const index = this.dadosExamesAso.indexOf(data);

      if (confirm('Deseja excluir o item?') && this.dadosExamesAso.splice(index, 1)) {
     //  await http.delete('/examesaso/'+data.id).then(resp => {
        
      //  if(resp.data){
     //   alert('Exame excluido com sucesso!')
     //   }
     //   this.buscaExamesAso()

     //   })
      }
    },

  }
}
</script>
<style >
.fonteCampoBotaoASO{
 
      color: #f5f8f7;
      font-size: 14px;
      font: bold 15px arial, verdana, helvetica, sans-serif;
      text-align: center;
      font-size: 16px;
      letter-spacing: -1px;
      line-height: 20px;

}

.alinhaFormAso{
    margin-left: 0px;
  }

  .alinhaFormAso2{
    margin-left: 200px;
  }

.fonteItem {
    font-size: 14px;
    font: bold 10px arial, verdana, helvetica, sans-serif;
    text-align: left;
    font-size: 16px;
    letter-spacing: -1px;
    line-height: 20px;
}

.input{
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
      box-shadow: 1px 1px 2px #333333;
        -moz-box-shadow: 1px 1px 2px #333333;
        -webkit-box-shadow: 1px 1px 2px #333333;
  background: #d8d7d7;
  border: 1px solid #000000;
  width: 150px;
  height: 25px;
}

.fonteTituloHeader {
  background-color: #e2e4e8;
  color: #31423b;
  font-size: 14px;
  font: bold 15px arial, verdana, helvetica, sans-serif;
  text-align: center;
  font-size: 16px;
  letter-spacing: -1px;
  line-height: 20px;
}

.fonteTituloFormRed {
  color: #c72126;
  font-size: 14px;
  font: bold 15px arial, verdana, helvetica, sans-serif;
  text-align: left;
  font-size: 16px;
  letter-spacing: -1px;
  line-height: 20px;
}

.fonteTituloForm{
  color: #31423b;
  font-size: 14px;
  font: bold 15px arial, verdana, helvetica, sans-serif;
  text-align: left;
  font-size: 16px;
  letter-spacing: -1px;
  line-height: 20px;
}

.fonteCampo {
    color: #747474;
    text-transform: uppercase;
    font-size: 16px;
    font: bold 15px arial, verdana, helvetica, sans-serif;
    text-align: left
}

.fonteCampoBotao {
  color: #747474;
  text-transform: uppercase;
  font-size: 16px;
  font: bold 15px arial, verdana, helvetica, sans-serif;
  text-align: left;
  list-style: none;
}

.fonteCampoBotao:hover,
.active {
   color: #f0f5f0 ;
}

.fonteLinhas {
  font-size: 14px;
  text-align: left

}

.fonteLinhasLeft {
  font-size: 14px;

}

.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}

.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.pesquisa_select {
  position: relative;
  margin-top: 20px;
}

.tituloFormulario{
  background-color: #a2a9b7;
  display: block;
  color: aliceblue;
  text-align: left;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;

  @if $columns {
    flex-direction: column;
  }
}
</style>