<template>
    <div id="ListAsosExame">
        <b-row>
            <b-col>
                <label><b>Asos Excluídas</b></label>
            </b-col>
        </b-row> 
        <b-row>
            <b-col>
                <p align="left">
                    <b-form-group label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm"
                        class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Pesquise aqui...">
                            </b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </p>
            </b-col>
        </b-row>
        <b-table striped hover responsive id="my-table" :items="listAsos" :fields="visibleFields" :per-page="perPage"
            :current-page="currentPage" :filter="filter" aria-controls="my-table" small>
          <template #cell(opcao)="data">
                        <b-icon icon="card-text" title="Vizualisar" cursor="pointer"
                         @click="visualizarAso(data.item)" pill v-b-modal="'edit-modal'" variant="danger" size="sm"></b-icon>
                           
                    </template>
        </b-table>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
        <!-- Info modal -->
                   <b-modal v-model="modalShow"  hide-footer>
                        <div>
                            <b-row>DADOS DE EDIÇÃO DA ASO</b-row>
                                    <b-row>
                                        <b-col> Codigo Aso
                                     </b-col>   
                                        <b-col>{{ formdata.aso }}</b-col>
                                    </b-row>
                        </div>
                    </b-modal>
    </div>
</template>

<script>

const moment = require("moment");
import { http } from '@/plugins/axios'
import maskMixins from '@/helpers/mixins/mask'
import { mapState  } from 'vuex'

export default {
    mixins: [maskMixins],
    data() {
        return {
            isBusy: false,
            perPage: 20,
            currentPage: 1,
            filter: null,
            fields: [
                {
                    key: 'id',
                    label: 'id',
                    visible: false,
                    sortable: true,
                    thStyle: { width: "1%" },
                    tdClass: 'fonteLinhasLeft'
                }, 
                {
                    key: 'codigoaso',
                    label: 'Codigo ASO',
                    visible: true,
                    sortable: true,
                    thStyle: { width: "3%" }
                }, 
                 {
                    key: 'empresa.nome',
                    label: 'Empresa',
                    visible: true,
                    sortable: true,
                    thStyle: { width: "25%" }
                },
                  {
                    key: 'paciente.nome',
                    label: 'Paciente',
                    visible: true,
                    sortable: true,
                    thStyle: { width: "20%" }

                },
                {
                    key: 'user.name',
                    label: 'Usuario Cadastro',
                    visible: true,
                    sortable: true,
                    thStyle: { width: "10%" }
                },
                    {
                    key: 'user_exclusao',
                    label: 'Usuario Exclusao',
                    visible: true,
                    sortable: true,
                    thStyle: { width: "10%" }
                },
                {
                    key: 'data_criacao',
                    label: 'Data Cadastro',
                    sortable: true,
                    visible: true,
                    thStyle: { width: "8%" },
                    tdClass: 'fonteLinhasLeft'
                },
                {
                key: 'data_exclusao',
                label: 'Data Exclusão',
                sortable: true,
                visible: true,
                thStyle: { width: "8%" },
                tdClass: 'fonteLinhasLeft'
                },
                {
                    key: 'opcao',
                    visible: true,
                    label: 'Opções',
                    thStyle: { width: "5%" },
                    sortable: true
                }
            ],
            formdata: {},
            examesAso: [],
            xml: '',
            dadosAso: [],
            listAsos: [],
            modalShow: false,
            editedIndex: -1
        }
    },
    mounted() {
        this.ActionFindAsos(),
            setTimeout(() => {// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                this.isBusy = false
            }, 2.0 * 2000)
    },
    computed: {
        ...mapState('auth', ['user']),
        visibleFields() {
            return this.fields.filter(field => field.visible)
        },
        rows() {
            return this.listAsos.length
        },
    },
    methods: {


        visualizarAso(item) {
            this.modalShow = true;
            this.editedIndex = this.listAsos.indexOf(item);
            this.formdata = Object.assign({}, item);
        },

        async ActionFindAsos() {
            await http.get('/aso/historico/asosexcluidas').then(resp => {
                (resp.data).forEach(function (item) {//tratando as datas 
                    item.data_exclusao = moment(item.data_exclusao).format("DD/MM/yyyy")
                    item.data_criacao = moment(item.data_criacao).format("DD/MM/yyyy")
                    
                     http.get('/user/'+item.user_exclusao).then(resp2 => {
                        item.user_exclusao = resp2.data.name
                    })


                })
                this.listAsos = resp.data
            })
        },

        mascaraCnpj(value) {
            var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
            return mascara;
        },
        formatarData: function (value) {
            if (value === null) { return null }
            return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })
        },
       
    }
}
</script>
<style >
.fonteLinhas {
    font-size: 14px;
    text-align: center
}

.fonteLinhasLeft {
    font-size: 14px;

}

.select-selected {
    border-color: black;
    border: 6px solid;
}

.select-selected.select-arrow-active:after {
    border-color: black;
    top: 7px;
}

.select-items div,
.select-selected {
    color: black;
    padding: 8px 16px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.select-items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

.select-hide {
    display: none;
}

.pesquisa_select {

    position: relative;
    margin-top: 20px;

}

</style>