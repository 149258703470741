<template>
    <div id="ExamesOcupacionais">
        <div>
            <b-row>
                <b-col  ><img src="@/assets/imgs/logo_ems10.png" class="img-logo_aso fonteLinhasLeft "> <br />&nbsp;<strong>EMS SEGURANÇA DO TRABALHO</strong><br/> &nbsp;&nbsp; <strong>CNPJ:</strong> 980.123.234/0001-00</b-col> 
            </b-row>
        </div>
    <b-container fluid="sm" class="border border-dark">
       
                    <b-row class="border border-dark text-center">
                        <b-col> <strong> ASO - ATESTADO DE SAÚDE OCUPACIONAL </strong> </b-col>
                    </b-row>
                <b-row >
                    <b-col class="border border-dark "><strong>Empresa:</strong> <span class="text-uppercase">{{ dados.empresa.nome }}</span></b-col><b-col><strong> Cnpj:</strong> {{ dados.empresa.cnpj }}</b-col>
                </b-row>
                <b-row>
                    <b-col class="border border-dark"><strong>Tipo Exame:</strong> <span class="text-uppercase">{{ dados.tipoaso.descricao }} </span></b-col>
                </b-row>

                <b-row>
                    <b-col class=" text-center"><strong>IDENTIFICAÇÃO DO TRABALHADOR</strong></b-col>
                </b-row>

                <b-row>
                    <b-col class="border border-dark"><strong>Nome Completo: </strong> <span class="text-uppercase">  {{ dados.paciente.nome }}</span></b-col><b-col class="border border-dark"><strong></strong>Cpf: {{ dados.paciente.cpf }}</b-col>
                </b-row>

                <b-row>
                    <b-col class="border border-dark"><strong>Função: </strong><span class="text-uppercase">{{ dados.paciente.funcao.name }}</span></b-col><b-col><strong>Matricula:</strong> {{ dados.paciente.matricula }}</b-col>
                </b-row>

                <b-row class="border border-dark text-center">
                        <b-col><strong>ATESTADO MÉDICO</strong></b-col>
                </b-row>

                    <b-row >
                        <b-col class="border border-dark text-center"><strong>Atesto que o trabalhador acima identificado submeteu-se aos exames médicos ocupacionais sendo considerado:</strong></b-col>
                    </b-row>

                    <b-row >
                        <b-col class="border border-dark text-left">(&nbsp;&nbsp;) APTO(A) </b-col> <b-col class="border border-dark text-left"> (&nbsp;&nbsp;) INAPTO(A) </b-col>
                    </b-row>

                <b-row class="border border-dark text-center">
                    <b-col><strong> RISCOS OCUPACIONAIS ESPECÍFICOS</strong></b-col>
                </b-row>

                <b-row class="border border-dark text-left">
                     <b-col class="border border-dark text-left">(&nbsp;&nbsp;)Físico </b-col> <b-col >(&nbsp;&nbsp;) Ergonômico </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-left">(&nbsp;&nbsp;)  Químico </b-col> <b-col class="border border-dark text-left">(&nbsp;&nbsp;) Acidentes </b-col>
                </b-row>

                <b-row>
                    <b-col class="border border-dark text-left" >(&nbsp;&nbsp;)  Biológico </b-col> <b-col class="border border-dark text-left" >(&nbsp;&nbsp;) Sem Riscos Específicos </b-col>
                </b-row>

                <b-row>
                    <b-col class="border border-dark text-center"><strong> PROCEDIMENTOS MÉDICOS REALIZADOS NO EXAME </strong></b-col>
                </b-row>

                <b-row class="espacoV">
                    <b-col >  </b-col>
                </b-row>

                <b-row>
                    <b-col class="border border-dark text-center"><strong> EXAMES COMPLEMENTARES REALIZADOS </strong></b-col>
                </b-row>

    <b-row>
    <template>

        <div>
            <b-table striped hover responsive id="my-table2" :items="dados.procedimentos" :fields="itemsExames" aria-controls="my-table" small>
            
            </b-table>
        </div>
    </template>
    </b-row>

                <b-row class="border border-dark text-center">
                    <b-col class="border border-dark text-left"><strong>TRABALHADOR APTO TAMBÉM PARA TRABALHO:</strong></b-col> <b-col class="border border-dark text-center">(&nbsp;&nbsp;) Em altura</b-col>   <b-col class="border border-dark text-center">(&nbsp;&nbsp;)Em espaço confinado</b-col> <b-col>(&nbsp;&nbsp;) Op. máq. ou veículos</b-col>
                </b-row>

                <b-row>
                    <b-col class="border border-dark text-left">
                        <strong>Médico do Trabalho: </strong><span class="text-uppercase"> {{ dados.medico.nome }} </span>
                    <br />
                    <br />
                    <br />
                    <span class="fonteLinhasData">Data:</span> ____/____/_____ 
                    </b-col> 
                    <b-col class=" text-left"> <strong>Médico Coordenador:</strong> NOME MEDICO EXAMEINADOR
                    <br />
                    <br />
                    <br />
                    <span class="fonteLinhasData">Data:</span> ____/____/_____ 
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col class="border border-dark p-3">Observação:</b-col> 
                </b-row>
</b-container>

</div>
</template>
<script>
//import { http } from '@/plugins/axios'
export default {

    props: {
        dados: [Object, Array],
    },

    data() {
        return {
            dadosExames: {},
            itemsExames: [
              
                {
                    key: 'exame.name',
                    label: 'Exame',
                    visible: true,
                    sortable: false,
                    thStyle: { width: "10%" },
                    tdClass: 'fonteLinhasLeft'
                },
                {
                    key: 'exame.procedimento.codigoesocial',
                    label: 'Codigo E-social',
                    visible: true,
                    sortable: false,
                    thStyle: { width: "10%" },
                    tdClass: 'fonteLinhasLeft'
                }
                
            ]
        }
    },
    mounted() {

    },
    created() {

    },
    computed: {

    },

    methods: {

    }

}
</script>
<style >
.img-logo_aso{
    float: left;
    margin-top: 0px;
    padding-bottom: 10px;

    width: 100px;
    height: 100px;
}

.fonteLinhas {
    font-size: 14px;
    text-align: center
}
.fonteLinhasData {
    font-size: 12px;
    text-align: center
}
.fonteLinhasLeft {
    font-size: 14px;

}

.select-selected {
    border-color: black;
    border: 6px solid;
}

.select-selected.select-arrow-active:after {
    border-color: black;
    top: 7px;
}

.select-items div,
.select-selected {
    color: black;
    padding: 8px 16px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.select-items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

.select-hide {
    display: none;
}

.pesquisa_select {

    position: relative;
    margin-top: 20px;

}

.espacoV {
    padding-bottom: 100px; 
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

@mixin flex-center($columns: false) {
    display: flex;
    align-items: center;
    justify-content: center;

    @if $columns {
        flex-direction: column;
    }
}
</style>